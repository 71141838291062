import React from 'react';

function Newsletter() {
  return (
    <section> 
    </section>
  );
}

export default Newsletter;
