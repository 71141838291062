import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Header() {

  const [top, setTop] = useState(true);

  // detect whether user has scrolled the page down by 10px 
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true)
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);  

  return (
    <header className={`fixed w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out ${!top && 'bg-white blur shadow-lg'}`}>
      <div className="max-w-6xl mx-auto px-5 sm:px-6">
        <div className="flex items-center justify-between h-16 md:h-20">

          {/* Site branding */}
          <Link to="/" className="inline-block" aria-label="DeepGenX">
          <div className="flex-shrink-0 mr-4">
            {/* Logo */}
        
            <img href="#" className="h-20 w-auto" src="https://avatars.githubusercontent.com/u/89645436?s=96&v=4" alt="Workflow" />
          </div>
          </Link>

          {/* Site navigation */}
          <nav className="flex flex-grow">
            <ul className="flex flex-grow justify-end flex-wrap items-center">
              <li>
                <a href="https://docs.deepgenx.com" className="font-medium text-gray-600 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out">Docs</a>
              </li>
              <li>
                <Link to="/signin" className="btn-sm text-white bg-blue-600 hover:bg-blue-700 ml-3">
                  <span>Get Api Key!</span>                  
                </Link>
              </li>
            </ul>               

          </nav>

        </div>
      </div>
    </header>
  );
}

export default Header;
