import React from 'react';
import { useState} from "react";
import Header from '../partials/Header';
import axios from 'axios';

function SignIn() {
  const [email, setName] = useState("");
  const handleSubmit = () => {
    const formData = new FormData();
    formData.append('email', `${email}`);
    axios.post('https://api.deepgenx.com:5700/register', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })//.then(function (response) {alert("Request has been sent.");}).catch(function (error) { alert(error); });
    alert(`Verification email has been sent to: ${email}`);
}
  return (    
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">

        <section className="bg-gradient-to-b from-gray-100 to-white">
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">

              {/* Page header */}
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                <h1 className="h1">Get your API key here 👇</h1>
              </div>

              {/* Form */}
              <div className="max-w-sm mx-auto">
                <form onSubmit={handleSubmit}>  
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="email">Email</label>
                      <input type="email" value={email} onChange={(e) => setName(e.target.value)}  className="form-input w-full text-gray-800" placeholder="Enter your email address" required />
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mt-6">
                    <div className="w-full px-3">
                      <button type="submit" className="btn text-white bg-blue-600 hover:bg-blue-700 w-full">Get API key!</button>
                    </div>
                  </div>
                </form>
                
              </div>
              
              {/*Send post request upon form submission */}
            </div>
          </div>
        </section>

      </main>

    </div>
  );
}
export default SignIn;
