import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">

        {/* Top area: Blocks */}
        <div className="grid sm:grid-cols-12 gap-8 py-8 md:py-12 border-t border-gray-200">

          {/* 1st block */}
          <div className="sm:col-span-12 lg:col-span-3">
            <div className="mb-2">
              {/* Logo */}
              <Link to="/" className="inline-block" aria-label="DeepGenX">
              <div className="flex-shrink-0 mr-4">
            {/* Logo */}
        
            <img className="h-20 w-auto" src="https://avatars.githubusercontent.com/u/89645436?s=96&v=4" alt="Workflow" />
          </div>
              </Link>
            </div>
          </div>

          {/* 2nd block */}
          <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
            <br/>
            <h6 className="text-gray-800 font-medium mb-2">
            <a href="https://github.com/DeepGenX/CodeGenX">
              GitHub
            </a>
           </h6>
          </div>

          {/* 3rd block */}
          <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
          <br/>
          <h6 className="text-gray-800 font-medium mb-2">
            <a href="https://marketplace.visualstudio.com/items?itemName=DeepGenX.codegenx">
              Marketplace
            </a>
           </h6>
          </div>

          {/* 4th block */}
          <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
          <br/>
          <h6 className="text-gray-800 font-medium mb-2">
            <a href="https://docs.deepgenx.com">
              Docs
            </a>
           </h6>
          </div>

                  {/* Success message */}
                  { /* <p className="mt-2 text-green-600 text-sm">Thanks for subscribing!</p> */}      

        </div>



      </div>
    </footer>
  );
}

export default Footer;